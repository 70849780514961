import React from "react"
import GlobalFooterItem from "./GlobalFooterItem"
import SingleReview from "../reviews/SingleReview"
import StaticReview from "../reviews/StaticReview"
import * as blockStyles from "../content-blocks/content-block.module.scss"
const GlobalFooter = ({
  content,
  phone,
  pestroutesReviews,
  staticReviews,
  reviewImages,
  meta,
}) => {
  const { global_footer_items } = content.value[0].elements
  const getSingleReview = id => {
    return pestroutesReviews.value[0].codename === "yes" ? (
      <SingleReview reviewImages={reviewImages} key={id} />
    ) : (
      <StaticReview
        reviewImages={reviewImages}
        staticReviews={staticReviews}
        key={id}
      />
    )
  }
  return (
    <section>
      {global_footer_items.value.map(({ id, elements, system }) => {
        if (system && system.type === "review_position") {
          return getSingleReview(id)
        } else {
          return (
            <div
              key={id}
              className={
                elements.alt_background_color !== undefined &&
                elements.alt_background_color.value[0] !== undefined &&
                elements.alt_background_color.value[0].codename === "yes"
                  ? blockStyles.altBg
                  : ""
              }
            >
              <GlobalFooterItem blocks={elements} phone={phone} meta={meta} />
            </div>
          )
        }
      })}
    </section>
  )
}

export default GlobalFooter
