import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import * as styles from "./blog.module.scss"
import { graphql, useStaticQuery } from "gatsby"
const BlogCard = ({ blog }) => {
  const { nodeEnvs } = useStaticQuery(getEnvs)
  const { blogParent } = nodeEnvs
  const { heading, featured_text, featured_image, slug, published_date } =
    blog.elements

  const truncString = str => {
    if (str.length <= 155) {
      return str
    } else {
      return `${str.slice(0, 155)}...`
    }
  }

  return (
    <article>
      <Img
        fluid={featured_image.value[0].fluid}
        className={styles.blogImage}
        alt={featured_image.value[0].description}
      />

      <h2 className={styles.blogTitle}>
        <Link to={`/blog/${blogParent + slug.value}`}>{heading.value}</Link>
      </h2>
      <p className={styles.pubDate}>{published_date.value}</p>
      <p className={styles.featuredText}>
        {truncString(featured_text.value)}
        <Link to={`/blog/${blogParent + slug.value}`}>Read More</Link>
      </p>
    </article>
  )
}

export const getEnvs = graphql`
  query Envs {
    nodeEnvs {
      blogParent
    }
  }
`

export default BlogCard
